@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap');
@import './mixins';
@import './colors';

/* http://meyerweb.com/eric/tools/css/reset/
	 v2.0 | 20110126
	 License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: auto;
	}
}

$primary: rgba(17, 83, 92, 1.0);

html, body {
	margin: 0;
	padding: 0;
}

body {
	background-color: $primary;
	font-family: 'Nunito', 'sans-serif';
	overflow-y: auto;
}

h1, h2, h3, h4, h5, h6 {
	color: $softWhite;
	text-align: center;
	width: 100%;
}

h1 {
	color: #BBB;
	font-size: 10vmin;
	font-weight: 700;
	font-family: 'Dancing Script', cursive;
}

h2 {
	font-size: 5vmin;
	font-weight: 500;
	color: rgb(0, 0, 0);
	font-family: 'Dancing Script', cursive;
}

a {
	cursor: pointer;
	color: lighten(rgba(33, 100, 135, .8), 25%);
	text-decoration: none;

	&:hover {
			color: lighten(rgba(33, 120, 125, .8), 8%);
	}
}

mark {
	color: lighten(rgba(33, 100, 135, .8), 25%);
	text-decoration: none;
	background: none;
}

.container {
	width: 100%;
	margin: 0;
	border-bottom: 0.1rem solid #BBB;
	position: fixed;
	background-color: rgba(50, 50, 50, 1.0);
	padding-top: 0.5%;
	padding-left: 2%;
	padding-right: 2%;
}

header {
	background: $primary;
	padding: 0;
	margin: 0;

	a {
		color: rgb(255, 255, 255);
		text-decoration: none;
	}

	a.logo {
		font-weight: bold;
	}

	nav {
		float: left;

		ul {
			list-style-type: none;
			margin: 0;
			display: flex;

			li a {
				padding: 1em;

					&:hover {
						background: rgba(35, 35, 35, .9);
					}
			}
		}
	}
}

table {
	display: inline-block;
	padding: auto;
	font-size: 5vmin;
	transform: translateY(-5%);

	tr{
		th {
			color: #000;
			text-align: center;
			padding: 0.25vmin 1vmin;
			user-select: none;
		}

		td {
			color: #BBB;
			text-align: center;
			padding: 0.25vmin 1vmin;
			user-select: none;

		}
	}
}