$softWhite: #BBB;
$softWhiteTransparent: #BBB5;
$transparentWhite: rgba(255, 255, 255, 0.5);
$blackTransparent: rgba(0, 0, 0, 0.5);
$primary: rgba(25, 25, 25, .9);
$scrollbar: rgb(42, 100, 116);
$scrollbarBackground: rgb(45, 45, 45);
$defaultBodyBackground: rgba(7, 7, 7, 0.9);
$grayGreenBackground: lighten(rgb(75, 107, 103), 15%);
// $grayGreenBackground: lighten(rgb(43, 74, 77), 8%);
// $grayGreenBackground: lighten(rgb(43, 50, 50), 35%);
$codeAreaFont:rgba(195, 195, 195, .9);
$defaultAnchor: lighten(rgba(33, 100, 135, .8), 25%);
$defaultAnchorHover: lighten(rgba(33, 120, 125, .8), 8%);
$topNavBackground: rgba(50, 50, 50, 1.0);
$topNavAnchorHoverBackground: rgba(35, 35, 35, .9);
$defaultTableBackground: rgba(33, 100, 116, .8);
$tableMark: rgb(95, 125, 175);
$lightImageGradientOverlay: linear-gradient(rgba(0,0,0,0.2), rgba(0,36,61,0.2));
$darkerImageGradientOverlay: linear-gradient(rgba(0,0,0,0.5), rgba(0,36,61,0.5));
$dropHoverShadow: rgba(0, 0, 0, 0.3);
$bioBackground: rgba(0, 0, 0, 0.2);
$articleBorder: rgba(150, 150, 150, 0.7);
$aboutAnchors: rgb(28, 85, 150);
$aboutAnchorsHover: $transparentWhite;