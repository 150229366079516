@import '../../../styles/mixins';

a.nextPrev {
	font-size: 10vmin;
	display: inline-block;
	position: relative;
	transition: color 0.4s;
}

a.prev {
	transform: translate(35%, 40%);
}

a.next {
	transform: translate(-35%, 40%);
}

figure.home_image {
	display: flex;
	height: 65vmin;
	width: 85vmin;

	@include xl_screen() {
		height: 70vmin;
		width: 90vmin;
	}

	img {
		position: relative;
		width: auto;
		height: auto;
		margin: 10% auto;
	}
}

.main {
	max-width: 95%;
	margin: 0 auto;
	text-align: center;

	h1 {
		padding-top: 10vh;
		padding-bottom: 0;
		margin: 0 auto;

		@include md_screen() {
			width: 100%;
		}
	}

	h2 {
		margin-bottom: 5rem;
	}
}

section.imageContainer {
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	user-select: none;
	width: 100%;
}

section.imageOuterContainer {

	@include sm_screen() {
		width: 80%;
	}

	@include md_screen() {
		width: 70%;
	}

	@include lg_screen() {
		width: 60%;
	}
}

section.imageSubContainer {
	background-color: #000;
	border: 0.3rem solid #AAA;
}