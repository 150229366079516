// .App {
//	 text-align: center;
// }
//
// .App-logo {
//	 height: 40vmin;
//	 pointer-events: none;
// }
//
// @media (prefers-reduced-motion: no-preference) {
//	 .App-logo {
//		 animation: App-logo-spin infinite 20s linear;
//	 }
// }
//
// .App-header {
//	 background-color: #282c34;
//	 min-height: 100vh;
//	 display: flex;
//	 flex-direction: column;
//	 align-items: center;
//	 justify-content: center;
//	 font-size: calc(10px + 2vmin);
//	 color: white;
// }
//
// .App-link {
//	 color: #61dafb;
// }
//
// @keyframes App-logo-spin {
//	 from {
//		 transform: rotate(0deg);
//	 }
//	 to {
//		 transform: rotate(360deg);
//	 }
// }

$primary: rgba(25, 25, 25, .9);

a {
	user-select: none;
	outline: none;
}

.navButton {

	text-decoration: none;
	user-select: none;
	outline: none;

	&:hover {
		color:rgb(225, 225, 225);
		background: none;
	}
}

.home_bio {
	user-select: none;
	background: none;
	color: rgb(255, 255, 255);
	padding: 0.5rem;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	position: relative;
	display: inline-block;

	&:hover {
		background-color: rgba(35, 35, 35, .9);
		background: none;
	}
}

.hover_button {
	background: none;
	color: rgb(255, 255, 255);
	padding: 0.5rem;
	font-size: 1rem;
	border: none;
	cursor: pointer;
}

.drop_div {
	position: relative;
	display: inline-block;
}

.hover_drop_list {
	display: none;
	position: absolute;
	background-color: rgb(225, 225, 225);
	text-align: center;
	min-width: 90px;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
	z-index: 1;

	a {
		color: black;
		padding: 0.25rem 0.25rem;
		text-decoration: none;
		display: block;
		font-size: 0.8rem;

		&:hover {
			background-color: lighten($primary, 55%);
		}
	}
}

.hover_drop_list_security {
	display: none;
	position: absolute;
	background-color: rgb(225, 225, 225);
	text-align: center;
	min-width: 4.5rem;
	box-shadow: 0rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.3);
	z-index: 1;

	a {
		color: black;
		padding: 0.25rem 0.25rem;
		text-decoration: none;
		display: block;
		font-size: 0.9rem;

		&:hover {
			background-color: rgba(35, 35, 35, .9);
		}
	}
}

.drop_div:hover .hover_drop_list {
	display: block;
}

.drop_div:hover .hover_drop_list_security {
	display: block;
}

.drop_div:hover .hover_button {
	background-color: rgba(35, 35, 35, .9);
}

li {
	padding-left: 1.1rem;
	padding-right: 1.1rem;
}

.about {
	user-select: none;
	background: none;
	color: rgb(255, 255, 255);
	padding: 0.5rem;
	font-size: 1rem;
	border: none;
	cursor: pointer;
	position: relative;
	display: inline-block;

	&:hover {
		background-color: rgba(35, 35, 35, .9);
	}
}

button {
	outline: none;
}