@import '../../../styles/mixins';
@import '../../../styles/colors';

.main {
	// max-width: 50vw;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 10vh;
	padding-top: 10vh;
}

hr {
	border-color: inherit;
}

.bio_h2 {
	color: $softWhite;
	font-family: 'Dancing Script', cursive;
	letter-spacing: 2px;
	font-size: 6vmin;
	// border-bottom: 1px solid black;
	padding: 4px;
	margin-bottom: 5px;
	width: 97%;
	max-width: 97%;

	@include xl_screen() {
		width: 97%;
		max-width: 97%;
		margin: 0 auto;
		margin-bottom: 5px;
	}
}

p {

	color: $softWhite;

	padding-top: 3vh;
	margin: 10px;
	margin-top: 5px;
	// font-size: 2.4rem;
	font-size: 1.3rem;

	@include xs_screen() {
		font-size: 1.5rem;
	}

	@include sm_screen() {
		// margin: 20px;
		// margin-top: 5px;
		// font-size: 2.1rem;
		font-size: 1.8rem;
	}
}

article {
	max-width: 85vw;
	margin: 10vh auto;
	border: 3px solid $blackTransparent;
	border-radius: 4px;
	background-color: $bioBackground;

	@include sm_screen() {
		max-width: 80vw;
	}

	@include md_screen() {
		max-width: 60vw;
	}

	@include lg_screen() {
		max-width: 50vw;
	}
}