@import '../../../styles/mixins';

.main {
	max-width: 80vw;
	margin: 0 auto;
	margin-bottom: 10vh;
	padding-top: 10vh;

	h1 {
		padding: 3vh 0;
		border: solid 0.3vmin #AAA;
		border-radius: 3px;
		background-color: rgb(42, 100, 122);
		margin: 0 auto;
	}
}

#gallery {
	width: 100%;
	margin: 0 auto;
	margin-bottom: 10vh;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: flex-start;
	align-content: center;
}

img {
	max-height: 80%;
	max-width: 80%;
	margin: 0 5vw;
	margin-top: 2vh;
}

.price {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: nowrap;
	align-content: center;
	align-items: flex-start;
}

.painting {
	width: 400px;
	height: 400px;
	margin: 5vh 0;
	border: 0.01em solid #AAA;
	background-color: #000;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex-wrap: nowrap;
	align-items: center;
	align-content: center;

	.price mark {
	margin-right: 1rem;
	}

	a {
	border: 1px solid lighten(rgba(33, 100, 135, .8), 25%);
	border-radius: 8px;
	padding: 0 10px;

	&:active {
		border-color: lighten(rgba(33, 120, 125, .8), 8%);
		border-style: outset;
		background-color: lighten(rgba(33, 120, 125, .8), 8%);
		color: black;
		border-style: inset;
	}

	@include non_touch_screen() {
		&:hover {
		border-color: lighten(rgba(33, 120, 125, .8), 8%);
		border-style: outset;
		background-color: lighten(rgba(33, 120, 125, .8), 8%);
		color: black;
		}

		&:active {
		border-style: inset;
		}
	} // only seems to work with 'all' no matter the order, not sure why
	// transition: color 300ms ease-in-out, background-color 300 ease-in-out, border-color 300 ease-in-out;
	transition: all 150ms ease-in-out;
	}
}

.placeholder {
	width: 400px;
	height: 400px;
	margin: 5vh 0;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	flex-wrap: nowrap;
	align-items: center;
	align-content: center;
}